import * as React from "react";

import { AIDescriptionInput } from "#/components/ai-description-input";
import { useEnabledAI } from "#/hooks/use-enabled-ai";
import { useTStream } from "#/hooks/use-t-stream";
import { cn } from "#/lib/utils";
import { Input as DeprecatedInput } from "#/ui/standard-input";
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full focus:ring-0 focus:border-primary rounded-xl border border-border bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

const DescriptionInput = ({ value, onChange, promptData, onAIGenerate }) => {
  const aiEnabled = useEnabledAI();
  const { t } = useTStream("common");

  return (
    <>
      {/** @TODO: Change this to be a flag from the POS config, so Generic POS can use it */}
      {aiEnabled ? (
        <AIDescriptionInput
          promptData={promptData}
          value={value}
          onChangeItem={(e) => {
            onAIGenerate(true);
            onChange(e);
          }}
        />
      ) : (
        <DeprecatedInput
          title={t("Description")}
          placeholder={t(
            "eg. Chicken Burger with lettuce, tomato, cheese, and mayo",
          )}
          rootStyles="mt-5 w-full"
          register={{
            value,
            onChange: (e) => {
              onAIGenerate(false);
              onChange(e);
            },
          }}
        />
      )}
    </>
  );
};

export { DescriptionInput, Input };
